import React, { useState } from 'react';

import { FormattedMessage, injectIntl } from '../../util/reactIntl';

import { Heading, NamedLink, IconEmailSent, InlineTextButton, IconClose } from '../../components';
import BlackLogoFleggs from '../../assets/Fleggs.jpg';

import css from './AuthenticationPage.module.css';

import loginPageImage from '../../assets/Onboarding-Profile-CreateListing.jpeg';
import classNames from 'classnames';
import UploadCredentialForm from './uploadCredentialForm';
import { compose } from 'redux';
import { createResourceLocatorString } from '../../util/routes';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';
import { withRouter } from 'react-router-dom';
import { ReactComponent as FleggsIcon } from '../../assets/fleggsBlackSvg.svg';

const EmailVerificationInfo = props => {
  const {
    name,
    email,
    onResendVerificationEmail,
    resendErrorMessage,
    sendVerificationEmailInProgress,
    history,
    onUpdateProfile,
  } = props;

  const [userTypeModal, setUserTypeModal] = useState(false);
  const [stepNumber, setStepNumber] = useState(0);
  const [userType, setUserType] = useState('Trainee');
  const [userTypeSelected, setUserTypeSelected] = useState(false);

  const routeConfiguration = useRouteConfiguration();

  // console.log(585, userTypeModal, userType);

  const resendEmailLink = (
    <InlineTextButton rootClassName={css.modalHelperLink} onClick={onResendVerificationEmail}>
      <FormattedMessage id="AuthenticationPage.resendEmailLinkText" />
    </InlineTextButton>
  );

  const fixEmailLink = (
    <NamedLink className={css.modalHelperLink} name="ContactDetailsPage">
      <FormattedMessage id="AuthenticationPage.fixEmailLinkText" />
    </NamedLink>
  );

  return (
    // <div className={css.content}>
    //   {!userTypeModal && stepNumber === 0 ? (
    //     <div className={css.joinOrSignParent}>
    //       <div className={css.joinOrSignImage}>
    //         <img src={loginPageImage} />
    //       </div>
    //       <div className={classNames(css.joinOrSignAuthentication, css.EmailVerifyWrap)}>
    //         {/* <NamedLink className={css.verifyClose} name="ProfileSettingsPage">
    //         <span className={css.closeText}>
    //           <FormattedMessage id="AuthenticationPage.verifyEmailClose" />
    //         </span>
    //         <IconClose rootClassName={css.closeIcon} />
    //       </NamedLink> */}
    //         {/* <IconEmailSent className={css.modalIcon} /> */}
    //         <Heading as="h1" rootClassName={css.modalTitle}>
    //           {/* <FormattedMessage id="AuthenticationPage.verifyEmailTitle" values={{ name }} /> */}
    //           We have sent you a verification email
    //         </Heading>
    //         <p className={css.modalMessage}>
    //           <FormattedMessage id="AuthenticationPage.verifyEmailText" values={{ email }} />
    //         </p>
    //         <button
    //           type="button"
    //           className={css.continueBtn}
    //           onClick={() => {
    //             setUserTypeModal(true);
    //             setStepNumber(1);
    //           }}
    //         >
    //           Continue
    //         </button>

    //         {resendErrorMessage}

    //         <div className={css.bottomWrapper}>
    //           <p className={css.modalHelperText}>
    //             {sendVerificationEmailInProgress ? (
    //               <FormattedMessage id="AuthenticationPage.sendingEmail" />
    //             ) : (
    //               <FormattedMessage
    //                 id="AuthenticationPage.resendEmail"
    //                 values={{ resendEmailLink }}
    //               />
    //             )}
    //           </p>
    //           <p className={css.modalHelperText}>
    //             <FormattedMessage id="AuthenticationPage.fixEmail" values={{ fixEmailLink }} />
    //           </p>
    //         </div>
    //       </div>
    //     </div>
    //   ) : stepNumber === 1 ? (
    //     <div className={css.joinOrSignParent}>
    //       <div className={css.joinOrSignAuthentication}>
    //         {/* <NamedLink name="LandingPage"> */}
    //         <img src={BlackLogoFleggs} alt="FullImg" className={css.fleggsImg} />
    //         {/* </NamedLink> */}

    //         <Heading as="h1" rootClassName={css.modalTitle}>
    //           Welcome to Fleggs, <span>{name}</span>
    //         </Heading>
    //         <div className={css.infoDiv}>
    //           <p className={css.modalMessage}>
    //             Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
    //             incididunt ut labore et dolore magna aliqua.
    //           </p>
    //         </div>

    //         <div className={css.userTypeDiv}>
    //           <div
    //             className={classNames({ [css.selectedUser]: userType === 'Trainee' })}
    //             onClick={() => {
    //               setUserType('Trainee');
    //             }}
    //           >
    //             joinOrSignAuthentication
    //           </div>
    //           <div
    //             className={classNames({ [css.selectedUser]: userType === 'Trainer' })}
    //             onClick={() => {
    //               setUserType('Trainer');
    //             }}
    //           >
    //             Become a Fleggs Trainer
    //           </div>
    //         </div>
    //         <div className={css.bottomDiv}>
    //           {/* <button>Do this later</button>
    //           <button>Skip</button> */}
    //           <button
    //             type="button"
    //             onClick={() => {
    //               setStepNumber(2);
    //               const profile = {
    //                 // bio,
    //                 publicData: {
    //                   isTrainer: userType === 'Trainer' ? true : false,
    //                 },
    //               };
    //               {
    //                 userType === 'Trainer'
    //                   ? onUpdateProfile(profile).then(() => {
    //                       history.push(
    //                         createResourceLocatorString(
    //                           'NewListingPage',
    //                           routeConfiguration,
    //                           {},
    //                           {}
    //                         )
    //                       );
    //                     })
    //                   : onUpdateProfile(profile);
    //               }
    //             }}
    //           >
    //             Next
    //           </button>
    //         </div>
    //       </div>
    //     </div>
    //   ) : stepNumber === 2 && userType === 'Trainee' ? (
    //     <div className={css.joinOrSignParent}>
    //       <div className={classNames(css.traineeContainer, css.changeWrap)}>
    //         <div className={css.middleInfo}>
    //           <NamedLink name="LandingPage">
    //             <img src={BlackLogoFleggs} alt="FullImg" className={css.fleggsImg} />
    //           </NamedLink>

    //           <div className={css.userTypeDiv}>
    //             <p>
    //               You’re all set! You can change your preferences whenever you like via your account
    //               settings.
    //             </p>
    //             <NamedLink name="ProfileSettingsPage">
    //               <button className={css.exploreBtn}>Explore Fleggs</button>
    //             </NamedLink>{' '}
    //           </div>
    //         </div>
    //         <div className={css.laterDiv}>
    //           <button>Do this later</button>
    //         </div>
    //       </div>
    //     </div>
    //   ) : stepNumber === 2 && userType === 'Trainer' ? (
    //     <div className={css.joinOrSignParent}>
    //       <div className={css.joinOrSignAuthentication}>
    //         <div className={css.topRow}>
    //           {/* <NamedLink name="LandingPage"> */}
    //           <img src={BlackLogoFleggs} alt="FullImg" className={css.fleggsImg} />
    //           {/* </NamedLink> */}
    //           <button
    //             type="button"
    //             onClick={() => {
    //               setStepNumber(1);
    //               setUserType('');
    //             }}
    //           >
    //             back
    //           </button>
    //         </div>
    //         <Heading as="h1" rootClassName={css.modalTitle}>
    //           Please provide your credentials
    //         </Heading>
    //         <div className={css.infoDiv}>
    //           <p className={css.modalMessage}>
    //             Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
    //             incididunt ut labore et dolore magna aliqua.
    //           </p>
    //         </div>

    //         <div>
    //           <UploadCredentialForm onUpdateProfile={onUpdateProfile} />
    //         </div>
    //         <div className={css.bottomDiv}>
    //           <button>Do this later</button>
    //           <button>Skip</button>
    //           <button
    //             type="button"
    //             onClick={() => {
    //               setStepNumber(3);
    //             }}
    //           >
    //             Next
    //           </button>
    //         </div>
    //       </div>
    //     </div>
    //   ) : stepNumber === 3 ? (
    //     <div className={classNames(css.joinOrSignParent, css.VerificationMain)}>
    //       <div className={css.traineeContainer}>
    //         <div className={css.middleInfo}>
    //           {/* <NamedLink name="LandingPage"> */}
    //           <img src={BlackLogoFleggs} alt="FullImg" className={css.fleggsImg} />
    //           {/* </NamedLink> */}

    //           <div className={css.userTypeDiv}>
    //             <p>
    //               Thank you! Please wait while we verify your credentials - this usually takes 24
    //               hours.
    //             </p>

    //             <button
    //               className={css.exploreBtn}
    //               onClick={() => {
    //                 history.push(
    //                   createResourceLocatorString('NewListingPage', routeConfiguration, {}, {})
    //                 );
    //               }}
    //             >
    //               Create Profile
    //             </button>
    //           </div>
    //         </div>
    //         <div className={css.laterDiv}>
    //           <button>Do This Later</button>
    //         </div>
    //       </div>
    //     </div>
    //   ) : null}{' '}
    // </div>
    <div className={css.content}>
      {!userTypeModal && stepNumber === 0 ? (
        <div className={css.joinOrSignParent}>
          <div className={css.joinOrSignImage}>
            <img src={loginPageImage} />
          </div>
          <div className={classNames(css.joinOrSignAuthentication, css.EmailVerifyWrap)}>
            {/* <NamedLink className={css.verifyClose} name="ProfileSettingsPage">
            <span className={css.closeText}>
              <FormattedMessage id="AuthenticationPage.verifyEmailClose" />
            </span>
            <IconClose rootClassName={css.closeIcon} />
          </NamedLink> */}
            {/* <IconEmailSent className={css.modalIcon} /> */}
            <Heading as="h1" rootClassName={css.modalTitle}>
              {/* <FormattedMessage id="AuthenticationPage.verifyEmailTitle" values={{ name }} /> */}
              We have sent you a verification email
            </Heading>
            <p className={css.modalMessage}>
              <FormattedMessage id="AuthenticationPage.verifyEmailText" values={{ email }} />
            </p>
            <button
              type="button"
              className={css.continueBtn}
              onClick={() => {
                setUserTypeModal(true);
                setStepNumber(1);
              }}
            >
              Continue
            </button>

            {resendErrorMessage}
            <div className={css.bottomMsgWrapper}>
              <div className={css.bottomWrapper}>
                <p className={css.modalHelperText}>
                  {sendVerificationEmailInProgress ? (
                    <FormattedMessage id="AuthenticationPage.sendingEmail" />
                  ) : (
                    <FormattedMessage
                      id="AuthenticationPage.resendEmail"
                      values={{ resendEmailLink }}
                    />
                  )}
                </p>
                <p className={css.modalHelperText}>
                  <FormattedMessage id="AuthenticationPage.fixEmail" values={{ fixEmailLink }} />
                </p>
              </div>

              <div className={css.footer}>
                By joining, you agree to the Fleggs Terms of Service and to occasionally receive
                emails from us. Please read our Privacy Policy to learn how we use your personal
                data.
              </div>
            </div>
          </div>
        </div>
      ) : stepNumber === 1 ? (
        <div className={classNames(css.joinOrSignParent, css.joinOrSignParentNew)}>
          <div className={css.joinOrSignAuthentication}>
            {/* <NamedLink name="LandingPage"> */}
            {/* <img src={BlackLogoFleggs} alt="FullImg" className={css.fleggsImg} /> */}
            <FleggsIcon className={css.fleggsImg} />
            {/* </NamedLink> */}

            <Heading as="h1" rootClassName={css.modalTitle}>
              Welcome to Fleggs, <span>{name}</span>
            </Heading>
            <div className={css.infoDiv}>
              <p className={css.modalMessage}>
                Thanks for signing up to Fleggs. So that we can create the right profile for you,
                please select from either of the below.
              </p>
            </div>

            <div className={css.userTypeDiv}>
              <div
                className={classNames({
                  [css.selectedUser]: userType === 'Trainee' && userTypeSelected,
                })}
                onClick={() => {
                  setUserType('Trainee');
                  setUserTypeSelected(true);
                }}
              >
                Train with Fleggs
              </div>
              <div
                className={classNames({
                  [css.selectedUser]: userType === 'Trainer' && userTypeSelected,
                })}
                onClick={() => {
                  setUserType('Trainer');
                  setUserTypeSelected(true);
                }}
              >
                Become a Fleggs Trainer
              </div>
            </div>
            <div className={css.bottomDiv}>
              {/* <button>Do this later</button>
              <button>Skip</button> */}
              <button
                type="button"
                disabled={!userTypeSelected}
                onClick={() => {
                  setStepNumber(2);
                  const profile = {
                    // bio,
                    publicData: {
                      isTrainer: userType === 'Trainer' ? true : false,
                    },
                  };
                  {
                    userType === 'Trainer'
                      ? onUpdateProfile(profile).then(() => {
                          history.push(
                            createResourceLocatorString(
                              'NewListingPage',
                              routeConfiguration,
                              {},
                              {}
                            )
                          );
                        })
                      : onUpdateProfile(profile);
                  }
                }}
              >
                Next
              </button>
            </div>
            <div className={css.footer}>
              By joining, you agree to the Fleggs Terms of Service and to occasionally receive
              emails from us. Please read our Privacy Policy to learn how we use your personal data.
            </div>
          </div>
        </div>
      ) : stepNumber === 2 && userType === 'Trainee' ? (
        <div className={css.joinOrSignParent}>
          <div className={classNames(css.traineeContainer, css.changeWrap)}>
            <div className={css.middleInfo}>
              <NamedLink name="LandingPage">
                <img src={BlackLogoFleggs} alt="FullImg" className={css.fleggsImg} />
              </NamedLink>

              <div className={css.userTypeDiv}>
                <p>
                  You’re all set! You can change your preferences whenever you like via your account
                  settings.
                </p>
                <NamedLink name="ProfileSettingsPage">
                  <button className={css.exploreBtn}>Explore Fleggs</button>
                </NamedLink>{' '}
              </div>
            </div>
            {/* <div className={css.laterDiv}>
              <button>Do this later</button>
            </div> */}
          </div>
        </div>
      ) : null}{' '}
    </div>
  );
};

export default compose(
  injectIntl,
  withRouter
)(EmailVerificationInfo);
