import React, { useEffect, useRef, useState, useMemo } from 'react';
import { arrayOf, bool, func, shape, string, array } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm, Field } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { isUploadImageOverLimitError } from '../../util/errors';
import {
  maxLength,
  required,
  composeValidators,
  nonEmptyArray,
  minLength,
} from '../../util/validators';
import {
  Form,
  Button,
  FieldTextInput,
  Modal,
  PrimaryButton,
  AddImages,
  ValidationError,
  ImageFromFile,
  ResponsiveImage,
  InfoModal,
  FieldCheckbox,
  ExternalLink,
  IconSpinner,
} from '../../components';
import * as validators from '../../util/validators';
import arrayMutators from 'final-form-arrays';
import css from './AuthenticationPage.module.css';
import axios from 'axios';
import { apiBaseUrl } from '../../util/api';

const UploadCredentialForm = props => {
  const {
    initialValues,
    onAddListingImage,
    listingId,
    onManageDisableScrolling,
    images,
    onUpdateListingImage,
    setLocalFormValues,
    onRemoveImage,
    isNewListingFlow,
    currentUser,
    onRemoveDummyImg,
  } = props;

  // const [imageUploadRequested, setImageUploadRequested] = useState(false);
  // const [isModalOpen, setIsModalOpen] = useState(false);
  // const [showCropImageModal, setShowCropImageModal] = useState(false);
  // const [cropImageIndx, setCropImageIndex] = useState(0);
  // const [showImgContainer, setShowImgContainer] = useState(false);
  // const [imageUpdateStatus, setImageUpdateStatus] = useState({
  //   isLoading: false,
  //   hasError: false,
  //   isCompleted: false,
  // });
  // const [isImgLoadCbReq, setIsImgLoadCbReq] = useState(false);
  // const [infoModalStatus, setInfoModalStatus] = useState({
  //   isOpen: false,
  //   msg: '',
  //   isError: false,
  // });
  // const [desginerReqInProgress, setDesginerReqInProgress] = useState(false);
  // const [isMenuOpen, setIsMenuOpen] = useState({
  //   [selectCategoryId]: false,
  //   [selectColorId]: false,
  //   [selectSizeId]: false,
  //   [selectBrandNameId]: false,
  // });
  // const [showsImages, setShowsImages] = useState(images);
  // useEffect(() => {
  //   // if (!isEqual(images, showsImages) && images.length === showsImages.length)
  //   //   setShowsImages(showsImages);
  //   // else setShowsImages(images);
  //   setShowsImages(images);
  // }, [images.length]);

  // const windowScrollPos = useRef(0);
  // const imgViewerRef = useRef(null);
  // const imgContainerRef = useRef(null);
  // const isDragging = useRef(false);
  // const dragged = useRef({ transx: 0, transy: 0 });
  // const preDragged = useRef({ transx: 0, transy: 0 });
  // const dragThres = useRef({
  //   min: { transx: 0, transy: 0 },
  //   max: { transx: 0, transy: 0 },
  //   diff: 0,
  // });
  // const scale = useRef(1);
  // const formApi = useRef(null);
  // const timeoutIds = useRef({
  //   [selectCategoryId]: null,
  //   [selectColorId]: null,
  //   [selectSizeId]: null,
  //   [selectBrandNameId]: null,
  // });

  // const onImageUploadHandler = (
  //   file,
  //   indx,
  //   disableImgUploadReqOnsuccess = true,
  //   isDummyImage = false
  // ) => {
  //   if (file) {
  //     setImageUploadRequested(true);
  //     return props
  //       .onImageUpload({ id: `${file.name}_${Date.now()}_${indx}`, file, isDummyImage })
  //       .then(resp => {
  //         if (!!listingId) {
  //           return onAddListingImage(resp.data.data.id, listingId);
  //         }
  //         return null;
  //       })
  //       .then(() => setImageUploadRequested(!disableImgUploadReqOnsuccess))
  //       .catch(() => {
  //         setImageUploadRequested(false);
  //       });
  //   }
  // };

  // const cropImage = async (file, form) => {
  //   //should implement pixel validation
  //   const reader = new FileReader();
  //   reader.onload = function(e) {
  //     const originalImg = new Image();
  //     originalImg.onload = async function() {
  //       const orgWidth = this.width;
  //       const orgHeight = this.height;
  //       const fileSize = (file.size || 0) / (1024 * 1024);
  //       if (orgHeight < MIN_IMG_HEIGHT || orgWidth < MIN_IMG_WIDTH) {
  //         setInfoModalStatus({
  //           isOpen: true,
  //           msg: `Photos are required to be at least ${MIN_IMG_WIDTH}x${MIN_IMG_HEIGHT} pixels in size.`,
  //           isError: false,
  //         });
  //         return;
  //       }
  //       if (fileSize > MAX_IMAGE_SIZE) {
  //         setInfoModalStatus({
  //           isOpen: true,
  //           msg: `Photos are required to be at most ${MAX_IMAGE_SIZE} MB in size.`,
  //           isError: false,
  //         });
  //         return;
  //       }
  //       await Promise.all([
  //         onImageUploadHandler(file, 0, true),
  //         onImageUploadHandler(file, 1, true),
  //       ]);
  //       form.change(`addImage`, file);
  //       form.blur(`addImage`);
  //     };
  //     originalImg.src = e.target.result;
  //   };
  //   reader.readAsDataURL(file);
  // };

  // const handleImgLoad = e => {
  //   if (imgViewerRef.current) {
  //     const height = e.target.offsetHeight;
  //     const imgWidth = e.target.offsetWidth;

  //     const width = imgWidth >= height ? height * ASSPECT_RATIO : imgWidth;
  //     const style = `width:${width}px;height:${height}px`;
  //     const children = imgViewerRef.current.children;
  //     children[0].setAttribute('style', style);
  //     children[2].setAttribute('style', `width:${width * 0.3333}px;height:${height}px`);
  //     children[3].setAttribute('style', `width:${width}px;height:${height * 0.33333}px`);
  //     const croppedImg = getImgViewerImgTag();
  //     const transx = (width - imgWidth) / 2;
  //     croppedImg.parentElement.setAttribute(
  //       'style',
  //       `transform:translate(${transx}px,0px) scale(1)`
  //     );
  //     croppedImg.setAttribute('style', `width:${imgWidth}px;height:${height}px`);
  //     e.target.parentElement.setAttribute('style', `transform:translate(0px,0px) scale(1)`);
  //     dragged.current = { transx: 0, transy: 0 };
  //     dragThres.current = {
  //       max: { transx: -1 * transx, transy: 0 },
  //       min: { transx: transx, transy: 0 },
  //       diff: transx,
  //     };
  //     setShowImgContainer(showCropImageModal);
  //     setIsImgLoadCbReq(false);
  //   }
  // };

  // const loadDataUriFromFile = (file, isContainerImg) => {
  //   const reader = new FileReader();
  //   reader.onloadend = function(e) {
  //     const result = e.target.result;
  //     const imgTag = isContainerImg ? getImgContainerImgTag() : getImgViewerImgTag();
  //     if (imgTag !== null && imgTag !== undefined) {
  //       imgTag.src = result;
  //     }
  //   };
  //   reader.readAsDataURL(file);
  // };

  // const getImgContainerImgTag = () => {
  //   if (imgContainerRef.current === null) return null;
  //   const [imgTag] = imgContainerRef.current.getElementsByTagName('img');
  //   return imgTag;
  // };

  // const getImgViewerImgTag = () => {
  //   if (imgViewerRef.current === null) return null;
  //   const [imgTag] = imgViewerRef.current.getElementsByTagName('img');
  //   return imgTag;
  // };

  return (
    <React.Fragment>
      <FinalForm
        {...props}
        // onImageUploadHandler={onImageUploadHandler}
        // imageUploadRequested={imageUploadRequested}

        render={formRenderProps => {
          const {
            // categories,
            className,
            disabled,
            ready,
            handleSubmit,
            intl,
            invalid,
            pristine,
            saveActionMsg,
            updated,
            updateInProgress,
            fetchErrors,
            form,
            values,
            images,
            imageUploadRequested,
            active,
            touched,
            errors,
            listing,
            availability,
            onUpdateProfile,
            onDataSubmit,
            onManageDisableScrolling,
            modalOpen,
            setModalOpen,
            history,
          } = formRenderProps;
          const [pdf, setPdf] = useState([]);
          const [isLoading, setIsLoading] = useState(false);
          const firstSubmit = !!listing?.attributes?.publicData?.Trainers_Credentials;
          const fileExists = !!values?.Trainers_Credentials?.[0];
          const equal = pdf?.name === fileExists?.name;
          const [awsDoc, setAwsDoc] = useState([]);
          const [selected, setSelected] = useState(false);
          const uploadDisabled =
            (awsDoc?.length === 0 && pdf?.length === 0) || isLoading || modalOpen;

          const memoizedValue = useMemo(() => {
            return values?.Trainers_Credentials || [];
          }, [values]);

          useEffect(() => {
            setAwsDoc(memoizedValue);
          }, [memoizedValue]);

          //Image
          const uploadDocText = (
            <>
              <span> Upload your Credentials</span>
              <p>.PDF,.DOC,.DOCX,.TXT,.JPG or .PNG Max 100MB</p>
            </>
          );

          // This error happens only on first tab (of EditListingWizard)

          const classes = classNames(css.root, className);

          const onUploadDocument = (file, form) => {
            if (file?.length > 0) {
              setIsLoading(true);
              // const { Trainers_Credentials } = values;
              // console.log(8080, Trainers_Credentials, values);
              const formData = new FormData();
              if (pdf?.length) {
                for (let file of pdf) formData.append('files', file);
              }
              const imgUpload = file
                ? axios.post(`${apiBaseUrl()}/api/document/upload`, formData, {
                    headers: {
                      'Content-Type': 'multipart/form-data',
                    },
                  })
                : Promise.resolve({ data: [] });
              imgUpload
                .then(res => {
                  form.change(`Trainers_Credentials`, [...res.data]);
                  // setModalOpen(true);
                  setIsLoading(false);
                  setPdf([]);
                  handleSubmit();
                  // const documentData = {
                  //   // bio,
                  //   publicData: {
                  //     doucumentLink: res?.data,
                  //   },
                  // };
                  // console.log(9999,documentData, res.data);

                  // onDataSubmit(documentData);
                  // onUpdateProfile(documentData);
                })
                .catch(err => {
                  console.log(555000, err);
                });
            } else {
              handleSubmit();
            }
          };

          // const onHandleSubmit = () => {
          //   const { Trainers_Credentials } = values;
          //   const formData = new FormData();
          //   if (Trainers_Credentials) {
          //     formData.append('files', Trainers_Credentials);
          //   }
          //   const imgUpload = Trainers_Credentials
          //     ? axios.post(`${apiBaseUrl()}/api/document/upload`, formData, {
          //         headers: {
          //           'Content-Type': 'multipart/form-data',
          //         },
          //       })
          //     : Promise.resolve({ data: [] });
          //   imgUpload.then(res => {
          //     console.log(333221, res);
          //   });
          // };

          const filterOut = fileName => {
            const filteredData = pdf?.filter(f => f.name !== fileName);
            setPdf(filteredData);
          };

          const awsPreviewFilterOut = file => {
            const filteredData = awsDoc?.filter(f => f?.key !== file?.key);
            setAwsDoc(filteredData);
          };

          return (
            <Form className={classes} onSubmit={handleSubmit}>
              <div className={css.descat}>
                <div>
                  <div className={css.inlinetxtmgw}>
                    {pdf.length > 0
                      ? pdf?.map((p, key) => (
                          <div className={css.pdfDiv} key={key}>
                            <ExternalLink href={URL.createObjectURL(p)}>{p?.name}</ExternalLink>
                            <button type="button" onClick={() => filterOut(p?.name)}>
                              <span>x</span>
                            </button>
                          </div>
                        ))
                      : awsDoc?.length > 0
                      ? awsDoc?.map(v => (
                          <div
                            // width="100%"
                            // height="600px"
                            // src={URL.createObjectURL(pdf)}
                            className={css.pdfDiv}
                          >
                            <ExternalLink href={v?.location}>
                              {v?.key?.split('/')?.[1]}
                            </ExternalLink>
                            <button
                              type="button"
                              onClick={() => {
                                awsPreviewFilterOut(v);
                              }}
                            >
                              <span>x</span>
                            </button>
                            {/* &nbsp;
                      <div
                        onClick={() =>
                          axios.delete(
                            `${apiBaseUrl()}/api/document/delete/${encodeURIComponent(
                              values?.Trainers_Credentials?.[0]?.key
                              )}`
                              )
                              }
                              >
                              remove
                              </div> */}
                          </div>
                        ))
                      : null}
                  </div>
                  <div className={css.credentaiDiv}>
                    <Field
                      id="Trainers_Credentials"
                      name="Trainers_Credentials"
                      accept=".pdf, .doc, .docx, .txt, image/*, video/*"
                      form={null}
                      label={uploadDocText}
                      type="file"
                      disabled={imageUploadRequested}
                    >
                      {fieldprops => {
                        const { accept, input, label, disabled: fieldDisabled } = fieldprops;
                        const { name, type } = input;
                        const onChange = e => {
                          setAwsDoc([]);
                          const file = e.target.files[0];
                          // setPdf(file);

                          //  form.change(`Trainers_Credentials`, file);
                          if (file !== null && file !== undefined) {
                            const reader = new FileReader();
                            let imageOrVideoFile;
                            if (/image\/.+|video\/.+/.test(file.type)) {
                              reader.onload = () => {
                                const [fileTypePrefix, fileExt] = file.type.split('/');
                                const regEx = new RegExp(`.${fileExt}`, 'gi');
                                const fileName = file.name.replace(regEx, '');
                                const newName = `${fileName}-${Date.now()}.${fileExt}`;
                                imageOrVideoFile = new File([file], newName, {
                                  type: file.type,
                                  lastModified: file.lastModified,
                                  lastModifiedDate: file.lastModifiedDate,
                                });
                                setPdf(p => [imageOrVideoFile]);
                              };
                              reader.readAsDataURL(file);
                            } else setPdf(p => [file]);
                            e.target.value = '';
                            if (!/safari/i.test(navigator.userAgent)) {
                              e.target.type = '';
                              e.target.type = 'file';
                            }
                          }
                        };
                        const inputProps = {
                          accept,
                          id: name,
                          name,
                          onChange,
                          type,
                        };
                        return (
                          <div className={css.addImageWrapper}>
                            <div className={css.aspectRatioWrapper}>
                              {fieldDisabled ? null : (
                                <input {...inputProps} className={css.uploadDocInput} />
                              )}
                              <label htmlFor={name} className={css.Trainers_Credentials}>
                                {label}
                              </label>
                            </div>
                          </div>
                        );
                      }}
                    </Field>
                  </div>
                </div>
                <div className={css.uploadDiv}>
                  <button className={css.goBack} type="button" onClick={() => history.goBack()}>
                    Go back
                  </button>
                  <button
                    className={css.uploadBtn}
                    type="button"
                    disabled={uploadDisabled}
                    onClick={() => onUploadDocument(pdf, form)}
                  >
                    {isLoading ? <IconSpinner /> : saveActionMsg}
                  </button>
                  {/* {values?.Trainers_Credentials?.length === 0 || values?.Trainers_Credentials?.length === undefined ? (
                    <button
                      className={css.uploadBtn}
                      type="button"
                      disabled={uploadDisabled}
                      onClick={() => onUploadDocument(pdf, form)}
                    >
                      {isLoading ? <IconSpinner /> : 'Upload and Publish'}
                    </button>
                  ) : (
                    <Button
                      className={css.submitButton1}
                      type="submit"
                
                      disabled={
                        (listing?.author?.attributes?.stripeConnected &&
                          history?.location?.pathname?.split('/')?.[
                            history?.location?.pathname?.split('/')?.length - 2
                          ] === 'edit') ||
                        modalOpen
                      }
                   
                    >
                      {saveActionMsg}
                   
                    </Button>
                  )} */}
                </div>

                <Modal
                  isOpen={modalOpen}
                  onClose={() => {
                    setModalOpen(false);
                  }}
                  className={css.modalClassName}
                  onManageDisableScrolling={onManageDisableScrolling}
                  showCloseButton={false}
                >
                  <p>
                    Thank you for creating a listing and submitting your credentials. Your profile
                    is currently under review. Once approved, your listing will automatically be
                    published, and you will receive a confirmation email and notification on Fleggs.
                  </p>
                  <p>
                    We aim to get back within a week. Please contact us at hello@fleggsit.com should
                    you not hear from us within 7 days of sending your credentials.
                  </p>

                  <div className={css.buttonContainer}>
                    <Button
                      className={css.submitButton}
                      type="submit"
                      // inProgress={submitInProgress}
                      // disabled={submitDisabled}
                      // ready={submitReady}
                    >
                      {/* {saveActionMsg} */}
                      OK
                      {/* save & continue */}
                    </Button>
                  </div>
                </Modal>
              </div>
            </Form>
          );
        }}
      />
    </React.Fragment>
  );
};

export default compose(injectIntl)(UploadCredentialForm);
